import { NgModule } from "@angular/core";
import { S25ConflictsComponent } from "./s25.conflicts.component";
import { CommonModule } from "@angular/common";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { S25PipesModule } from "../../pipes/s25.pipes.module";

@NgModule({
    imports: [CommonModule, S25ItemModule, S25PipesModule],
    exports: [S25ConflictsComponent],
    providers: [S25ConflictsComponent],
    declarations: [S25ConflictsComponent],
})
export class S25ConflictsModule {}
