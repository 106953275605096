import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";
import { UserprefService } from "../../services/userpref.service";
import { PreferenceService } from "../../services/preference.service";
import { TableColumnI } from "../../pojo/TableColumnI";
import { TypeManagerDecorator } from "../../main/type.map.service";

export interface ColumnChooserModelI {
    colList: TableColumnI[];
    listColumnPrefName?: string;
    isInline?: boolean;
    updateCallback?: Function;
    setCols?: Function; // Set by component
}

@TypeManagerDecorator("s25-ng-column-chooser")
@Component({
    selector: "s25-ng-column-chooser",
    template: `
        @if (init) {
            <div>
                <s25-ng-multiselect-popup
                    [modelBean]="selectBean"
                    [ngClass]="{ ngRelative: !modelBean.isInline }"
                ></s25-ng-multiselect-popup>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ColumnChooserComponent implements OnInit {
    @Input() modelBean: ColumnChooserModelI;
    selectBean: MultiselectModelI;
    init = false;

    constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit() {
        // Expose setCols method to caller through the input object
        this.modelBean.setCols = this.setCols;

        // Initialize the checkboxes
        this.setMultiselectBean();
        this.init = true;
    }

    // Convert a column item to an item to be used in the multiselect model
    convertColumnToItem(column: TableColumnI, i: number) {
        return {
            itemName: column.name,
            itemId: i,
            checked: !!column.isVisible,
            isPermanent: !!column.isPermanent,
        };
    }

    // Update the multiselect interface
    setMultiselectBean() {
        // Extract items from table columns
        const colData = this.modelBean.colList.map((col, i) => this.convertColumnToItem(col, i));
        const items = colData.filter((item, i) => this.modelBean.colList[i]?.prefname && item.itemName);
        this.selectBean = {
            title: "Columns",
            items,
            selectedItems: items.filter((item) => item.checked),
            hasFilter: false,
            hasSelectAll: false,
            hasSelectNone: false,
            onChange: this.update,
        };
    }

    // Resets visible columns to default
    reset() {
        for (let column of this.modelBean.colList) {
            column.isVisible = column.isDefaultVisible;
        }
        this.setMultiselectBean();
    }

    // Method is exported through modelBean
    // Changes which columns are available
    setCols = (columns: TableColumnI[]) => {
        if (!columns?.length) return;
        this.modelBean.colList = columns;
        this.reset();
    };

    // Update the table on Multiselect change
    update = () => {
        // Update model
        for (let column of this.modelBean.colList) if (column.name && column.prefname) column.isVisible = 0;
        for (let item of this.selectBean.selectedItems) this.modelBean.colList[item.itemId as number].isVisible = 1;
        // Update user preferences
        if (this.modelBean.listColumnPrefName) {
            UserprefService.getLoggedIn().then((isLoggedIn) => {
                if (!isLoggedIn) return;
                PreferenceService.updateSearchColListPref(this.modelBean.listColumnPrefName, this.modelBean.colList); // Update DB
            });
        }
        // Update table
        this.modelBean.updateCallback();
    };
}
