//@author: travis
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { EChartsOption } from "echarts";
import { SpaceService } from "../../services/space.service";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-e-charts")
@Component({
    selector: "s25-ng-e-charts",
    template: `
        @if (init) {
            <div>
                <div echarts [options]="chartOption"></div>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    // changeDetection: ChangeDetectionStrategy.Default,
})
export class S25EChartsComponent implements OnInit {
    @Input() spaceId: number;

    chartOption: EChartsOption = {};
    init: boolean = false;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    async ngOnInit() {
        const data = await SpaceService.getSpaceSatisfaction(this.spaceId);
        const xAxis: (number | string)[] = [];
        const yAxis: number[] = [];

        data?.forEach((term) => {
            xAxis.push(term.term_code);
            yAxis.push(term.rating);
        });

        this.chartOption = {
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
            },
            formatter: (params: any) => {
                const termName = this.getTermName(params[0].name);
                const endDate = data?.find((term) => term.term_code.toString() === params[0].name);
                return `<p style="font-size: 20px;">${termName}</p> Term Code: <br/> ${params[0].name} <br/> End Date: <br/> ${endDate.end_dt} `;
            },
            xAxis: {
                type: "category",
                boundaryGap: false,
                data: xAxis,
            },
            yAxis: {
                type: "value",
            },
            series: [
                {
                    data: yAxis,
                    type: "line",
                    areaStyle: {},
                },
            ],
        };

        this.init = true;
        this.cd.detectChanges();
    }

    getTermName(termCode: string) {
        const quarterMap: any = {
            "1": "Winter",
            "2": "Spring",
            "3": "Summer",
            "4": "Fall",
        };

        const year = termCode.slice(0, 4);
        const term = quarterMap[termCode[termCode.length - 1]];

        return `${term} ${year}`;
    }
}
