//@author: travis
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25ContactInfoComponent } from "./s25.contact.info.component";
import { S25AddressModule } from "../s25-address/s25.address.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";

@NgModule({
    declarations: [S25ContactInfoComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        S25EditableModule,
        S25ItemModule,
        S25LoadingInlineModule,
        S25AddressModule,
        S25CheckboxModule,
    ],
    providers: [S25ContactInfoComponent],
    exports: [S25ContactInfoComponent],
})
export class S25ContactInfoModule {
    constructor() {}
}
