//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25DarkmodeComponent } from "./s25.darkmode.component";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";

@NgModule({
    declarations: [S25DarkmodeComponent],
    imports: [CommonModule, FormsModule, S25ToggleButtonModule],
    providers: [S25DarkmodeComponent],
    exports: [S25DarkmodeComponent],
})
export class S25DarkmodeModule {
    constructor() {}
}
