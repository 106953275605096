import { NgModule } from "@angular/core";
import { S25ColumnChooserComponent } from "./s25.column.chooser.component";
import { CommonModule } from "@angular/common";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";

@NgModule({
    declarations: [S25ColumnChooserComponent],
    imports: [CommonModule, S25MultiselectModule],
    providers: [S25ColumnChooserComponent],
    exports: [S25ColumnChooserComponent],
})
export class S25ColumnChooserModule {
    constructor() {}
}
