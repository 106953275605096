//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25ConstraintsComponent } from "./s25.constraints.component";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25DatePatternModule } from "../s25-datepattern/s25.datepattern.module";

@NgModule({
    declarations: [S25ConstraintsComponent],
    imports: [CommonModule, FormsModule, S25EditableModule, S25DatePatternModule],
    providers: [S25ConstraintsComponent],
    exports: [S25ConstraintsComponent],
})
export class S25ConstraintsModule {
    constructor() {}
}
