//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { PreferenceService } from "../../services/preference.service";
import { Preference } from "../../pojo/PeferenceI";

@TypeManagerDecorator("s25-darkmode")
@Component({
    selector: "s25-darkmode",
    template: `
        @if (this.init) {
            <div>
                <label for="show" class="ngBold c-margin-bottom--none"> Enable Dark Mode </label>
                <s25-toggle-button
                    (modelValueChange)="this.onChange($event)"
                    [modelValue]="this.darkmode"
                    [toggleLabel]="'Enable Dark Mode'"
                    [trueLabel]="trueLabel"
                    [falseLabel]="falseLabel"
                ></s25-toggle-button>
                <!--
            <div class="c-displayBlock c-padding-bottom--double">
              <button class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter" (click)="save()" [disabled]="this.finalDisplayStart === '' || this.finalDisplayEnd === '' || this.endTimeError"> {{this.loading ? 'Saving....' : 'Save'}} </button>
              <button class="aw-button aw-button--outline c-margin-top--single" (click)="cancel()"> Cancel </button>
            </div>
            -->
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25DarkmodeComponent implements OnInit {
    @Input() prefType: Preference.PrefType = "U";
    @Input() falseLabel: string = "No";
    @Input() trueLabel: string = "Yes";
    @Output() modelValueChange = new EventEmitter();
    loading: boolean = false;
    init: boolean = false;
    darkmode: boolean = false;
    initDarkmode: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.getPrefs();
        this.cd.detectChanges();
    }

    getPrefs() {
        return S25Util.all({
            prefs: PreferenceService.getPreferences(["pro_dark_mode"], this.prefType),
        }).then((resp) => {
            resp.prefs.pro_dark_mode.value === "T" ? (this.darkmode = true) : (this.darkmode = false);
            resp.prefs.pro_dark_mode.value === "T" ? (this.initDarkmode = true) : (this.initDarkmode = false);
            //this.darkmode = this.initDarkmode = resp.prefs.pro_dark_mode.value;
            this.init = true;
            this.cd.detectChanges();
        });
    }

    onChange(event: any) {
        this.darkmode = event;
        S25Util.setDarkMode(event);
        //console.log({event: event, initDarkmode: this.initDarkmode, darkmode : this.darkmode});
        let promiseArr = [];
        promiseArr.push(PreferenceService.setPreference("pro_dark_mode", event ? "T" : "F", this.prefType));
        if (promiseArr) {
            return S25Util.all(promiseArr).then((resp) => {
                this.loading = false;
                this.cd.detectChanges();
            });
        }
        this.cd.detectChanges();
    }
    /*
    save(){    
        this.loading = true;
        this.cd.detectChanges();
        let promiseArr = [];
        this.initDarkmode !== this.darkmode && promiseArr.push(PreferenceService.setPreference("pro_dark_mode", this.darkmode ? "T": "F", this.prefType));
        if(promiseArr){
            return S25Util.all(promiseArr).then((resp) =>{
                this.loading = false;
                this.cd.detectChanges();
             });
        }
     }

    cancel(){
        this.getPrefs();
    }
*/
}
