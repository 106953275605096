//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25ChartsComponent } from "./s25.charts.component";
import { NgxEchartsDirective, provideEcharts } from "ngx-echarts";
import { S25EChartsComponent } from "./s25.echarts.component";

// //https://github.com/xieziyu/ngx-echarts/issues/308 ran into this error on gauss build
// export function load_echarts() {
//     return import("echarts");
// }

@NgModule({
    declarations: [S25ChartsComponent, S25EChartsComponent],
    imports: [CommonModule, FormsModule, NgxEchartsDirective],
    providers: [S25ChartsComponent, S25EChartsComponent, provideEcharts()],
    exports: [S25ChartsComponent, S25EChartsComponent],
})
export class S25ChartsModule {
    constructor() {}
}
