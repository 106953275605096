import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { LangService } from "../../services/lang.service";
import { FlsService } from "../../services/fls.service";
import { ContactService } from "../../services/contact.service";
import { PreferenceService } from "../../services/preference.service";
import { AddressI } from "../../pojo/AddressI";
import { StateService } from "../../services/state.service";
import { S25ItemI } from "../../pojo/S25ItemI";

@TypeManagerDecorator("s25-ng-contact-info")
@Component({
    selector: "s25-ng-contact-info",
    template: `@if (this.init) {
        <div>
            <!-- Defining templates for conditionally rendered and movable form components --->
            <ng-template #emailForm>
                <div class="email-container">
                    <div class="ngBold email-header">Email</div>
                    <div class="UserPreferenceContent">
                        <div class="email-details--wrapper" [ngClass]="{ 'read-only': !canEditEmail }">
                            <div>
                                <label for="workEmail"
                                    >Work Email
                                    @if (canEditContact) {
                                        <span class="required">*</span>
                                    }
                                </label>
                                <s25-ng-editable-text
                                    [fieldID]="'workEmail'"
                                    [min]="2"
                                    [max]="80"
                                    [alwaysEditing]="true"
                                    [readOnly]="!this.canEditContact"
                                    [(val)]="this.workAddr.email"
                                    (valChange)="updateContactModel()"
                                ></s25-ng-editable-text>
                            </div>
                            <div>
                                <label for="homeEmail">Home Email</label>
                                <s25-ng-editable-text
                                    [fieldID]="'homeEmail'"
                                    [max]="80"
                                    [alwaysEditing]="true"
                                    [readOnly]="!this.canEditContact"
                                    [(val)]="this.homeAddr.email"
                                    (valChange)="updateContactModel()"
                                ></s25-ng-editable-text>
                            </div>
                            @if (dupContactMessage && data.copyContact) {
                                <div class="confirm-dups">
                                    <p class="error-message">{{ dupContactMessage }}</p>
                                    @if (exactContactMatches.length > 0) {
                                        <div class="match-container">
                                            @for (match of exactContactMatches; track match.itemId) {
                                                <p class="error-message">{{ match.itemName }}</p>
                                            }
                                        </div>
                                    }
                                    <div class="button-group">
                                        <button
                                            class="aw-button aw-button--danger--outline"
                                            (click)="findDuplicateContacts(storedCopyAction, storedObjectProps)"
                                        >
                                            Confirm
                                        </button>
                                        <button class="aw-button aw-button--outline" (click)="cancelConfirm()">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #emailOptionsForm>
                <div class="email-signature">
                    <div>
                        <label for="emailSignature" class="ngBold">Email Signature</label>
                        <br />
                        <s25-ng-editable-richtext
                            [(val)]="this.data.EmailSignature"
                            (valChange)="updateEmailSignature($event)"
                            [fieldID]="'emailSignature'"
                            [alwaysEditing]="true"
                            onfocus="this.select()"
                            style="width:100%; height:150px; margin-top: 5px;"
                        ></s25-ng-editable-richtext>
                    </div>
                    <div>
                        <div>
                            <s25-ng-checkbox
                                [(modelValue)]="data.EmailCopyMe"
                                (modelValueChange)="updateEmailCopyMe($event)"
                                >Copy myself on all emails I send</s25-ng-checkbox
                            >
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #addressForms>
                <div class="work-address-wrapper">
                    <div class="editable-address-header">
                        <div
                            class="ngBold work-address"
                            (keydown.enter)="toggleForm('work')"
                            (click)="toggleForm('work')"
                            tabindex="0"
                        >
                            Work Address
                        </div>
                        @if (!displayWorkAddressFrom && data.eventForm) {
                            <svg class="c-svgIcon" role="img">
                                <title>Expand</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#chevron-down"
                                ></use>
                            </svg>
                        }
                        @if (displayWorkAddressFrom && data.eventForm) {
                            <svg class="c-svgIcon" role="img">
                                <title>Collapse</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#chevron-up"
                                ></use>
                            </svg>
                        }
                    </div>
                    @if (displayWorkAddressFrom || !data.eventForm) {
                        <div class="UserPreferenceContent">
                            <s25-ng-address [model]="this.workAddr" [readOnly]="!this.canEditContact"></s25-ng-address>
                        </div>
                    }
                </div>
                <div class="home-address-wrapper">
                    <div class="editable-address-header">
                        <div
                            class="ngBold home-address"
                            (keydown.enter)="toggleForm('home')"
                            (click)="toggleForm('home')"
                            tabindex="0"
                        >
                            Home Address
                        </div>
                        @if (!displayHomeAddressForm && data.eventForm) {
                            <svg class="c-svgIcon" role="img">
                                <title>Expand</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#chevron-down"
                                ></use>
                            </svg>
                        }
                        @if (displayHomeAddressForm && data.eventForm) {
                            <svg class="c-svgIcon" role="img">
                                <title>Collapse</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#chevron-up"
                                ></use>
                            </svg>
                        }
                    </div>
                    @if (displayHomeAddressForm || !data.eventForm) {
                        <div class="UserPreferenceContent">
                            <s25-ng-address
                                [(model)]="this.homeAddr"
                                [readOnly]="!this.canEditContact"
                            ></s25-ng-address>
                        </div>
                    }
                </div>
            </ng-template>
            @if (showSuccessMsg) {
                <div class="success-msg">
                    <div>
                        <svg class="c-svgIcon" role="img">
                            <title>Success</title>
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                            ></use>
                        </svg>
                        Contact Successfully Created
                    </div>
                    <div class="modal-footer">
                        <button class="aw-button aw-button--outline" (click)="goToRecord()">
                            View Contact Details
                        </button>
                        <button class="aw-button aw-button--outline c-margin-left--single" (click)="data.closeModal()">
                            Close
                        </button>
                    </div>
                </div>
            }
            @if (!showSuccessMsg) {
                <div class="contact-info--wrapper" [ngClass]="{ 'event-form-modal': data.eventForm }">
                    <div class="basic-info" [ngClass]="{ 'read-only': !canEditContact }">
                        <div class="ngBold basic-info-header">Basic Information</div>
                        <div class="UserPreferenceContent">
                            <div>
                                <label for="namePrefix">{{ lang.name_prefix }}</label>
                                <s25-ng-editable-text
                                    [fieldID]="'namePrefix'"
                                    [max]="20"
                                    [alwaysEditing]="true"
                                    [readOnly]="!this.canEditContact"
                                    [(val)]="this.contact.name_prefix"
                                    (valChange)="updateContactModel('name_prefix', $event)"
                                ></s25-ng-editable-text>
                            </div>
                            <div>
                                <label for="firstName">{{ lang.first_name }}</label>
                                <s25-ng-editable-text
                                    [fieldID]="'firstName'"
                                    [max]="40"
                                    [alwaysEditing]="true"
                                    [readOnly]="!this.canEditContact"
                                    [(val)]="this.contact.first_name"
                                    (valChange)="updateContactModel('first_name', $event)"
                                ></s25-ng-editable-text>
                            </div>
                            <div>
                                <label for="middleName">{{ lang.middle_name }}</label>
                                <s25-ng-editable-text
                                    [fieldID]="'middleName'"
                                    [max]="80"
                                    [alwaysEditing]="true"
                                    [readOnly]="!this.canEditContact"
                                    [(val)]="this.contact.middle_name"
                                    (valChange)="updateContactModel('middle_name', $event)"
                                ></s25-ng-editable-text>
                            </div>
                            <div>
                                <label for="lastName"
                                    >{{ lang.last_name }}
                                    @if (canEditContact) {
                                        <span class="required">*</span>
                                    }
                                </label>
                                <s25-ng-editable-text
                                    [fieldID]="'lastName'"
                                    [max]="40"
                                    [alwaysEditing]="true"
                                    [readOnly]="!this.canEditContact"
                                    [(val)]="this.contact.last_name"
                                    (valChange)="updateContactModel('last_name', $event)"
                                ></s25-ng-editable-text>
                            </div>
                            <div>
                                <label for="nameSuffix">{{ lang.name_suffix }}</label>
                                <s25-ng-editable-text
                                    [fieldID]="'nameSuffix'"
                                    [max]="40"
                                    [alwaysEditing]="true"
                                    [readOnly]="!this.canEditContact"
                                    [(val)]="this.contact.name_suffix"
                                    (valChange)="updateContactModel('name_suffix', $event)"
                                ></s25-ng-editable-text>
                            </div>
                            <div>
                                <label for="title">{{ lang.title }}</label>
                                <s25-ng-editable-text
                                    [fieldID]="'title'"
                                    [max]="40"
                                    [alwaysEditing]="true"
                                    [readOnly]="!this.canEditContact"
                                    [(val)]="this.contact.title"
                                    (valChange)="updateContactModel('title', $event)"
                                ></s25-ng-editable-text>
                            </div>
                            <div>
                                <label for="internalId">{{ lang.internal_id }}</label>
                                <s25-ng-editable-text
                                    [fieldID]="'internalId'"
                                    [max]="40"
                                    [alwaysEditing]="true"
                                    [readOnly]="!this.canEditContact"
                                    [(val)]="this.contact.internal_id"
                                    (valChange)="updateContactModel('internal_id', $event)"
                                ></s25-ng-editable-text>
                            </div>
                        </div>
                    </div>
                    <!-- above-defined templates rendered as needed --->
                    @if ((data.eventForm || data.copyContact) && fields.showEmail) {
                        <ng-container *ngTemplateOutlet="emailForm"></ng-container>
                    }
                    @if (fields.showAddress) {
                        <ng-container *ngTemplateOutlet="addressForms"></ng-container>
                    }
                    @if (data.userSettings && fields.showEmail) {
                        <ng-container *ngTemplateOutlet="emailForm"></ng-container>
                    }
                    @if (fields.hasEmailOptions) {
                        <ng-container *ngTemplateOutlet="emailOptionsForm"></ng-container>
                    }
                </div>
            }
            @if (data.userSettings || (data.eventForm && !showSuccessMsg)) {
                <div class="submit-group" [ngClass]="{ 'confirm-dups': dupContactMessage }">
                    @if (dupContactMessage) {
                        <p class="error-message">{{ dupContactMessage }}</p>
                    }
                    @if (exactContactMatches.length > 0) {
                        <div class="match-container">
                            @for (match of exactContactMatches; track match.itemId) {
                                <p class="error-message">{{ match.itemName }}</p>
                            }
                        </div>
                    }
                    <div class="confirm-buttons">
                        <button
                            (click)="findDuplicateContacts()"
                            [disabled]="disableSaveButton"
                            class="aw-button {{
                                dupContactMessage ? 'aw-button--danger--outline' : 'aw-button--primary'
                            }}"
                        >
                            {{ dupContactMessage && data.eventForm ? "Confirm" : "Save" }}
                        </button>
                        @if (dupContactMessage && data.eventForm) {
                            <button (click)="cancelConfirm()" class="aw-button aw-button--outline">Cancel</button>
                        }
                        <s25-loading-inline
                            [model]="{ text: 'Saving...' }"
                            class="loading-spinner"
                        ></s25-loading-inline>
                    </div>
                </div>
            }
        </div>
    }`,
    styles: `
        .contact-info--wrapper > * {
            max-width: 100%;
        }

        :host ::ng-deep label {
            min-width: 8rem;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ContactInfoComponent implements OnInit {
    @Input() isNew: boolean = false;
    @Input() itemId?: number;
    @Input() contact: any; //contact
    @Input() fields: { hasEmailOptions: boolean; showEmail: boolean; showAddress: boolean };
    @Input() onSave?: (copyAction: "edit" | "create", props: any) => void;

    @Input() data: any = {};

    @Output() contactChange = new EventEmitter<any>();
    init = false;
    lang: any = {};
    canEditEmail: boolean = true;
    canEditContact: boolean;
    contactModel: any = {};
    workAddr: AddressI = {};
    homeAddr: AddressI = {};
    initialState: any;
    displayWorkAddressFrom: boolean;
    displayHomeAddressForm: boolean;
    showSuccessMsg: boolean;
    disableSaveButton: boolean = false;
    dupContactMessage: string;
    exactContactMatches: S25ItemI[] = [];
    storedCopyAction: "edit" | "create";
    storedObjectProps: any;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}
    /**
     * Should be able to use this in a few ways -
     * Create a new user (isNew == true)
     * Update existing user (isNew == False)
     * Copy a user (isNew == true && itemId == currentId)
     */
    ngOnInit(): void {
        this.data.showEmail = false;
        S25LoadingApi.init(this.elementRef.nativeElement);
        S25Util.all({
            lang: LangService.getLang(),
            currentId: ContactService.getCurrentId(),
        }).then((setup) => {
            this.lang = setup.lang.div.controls.application.text.contact_info;
            S25Util.all({
                contact:
                    !this.isNew &&
                    // this.itemId === currentId &&
                    ContactService.getCurrentContact("extended", ["address"]),
                fls: !this.isNew && FlsService.getFls(),
                prefs: !this.isNew && PreferenceService.getPreferences(["EmailCopyMe", "EmailSignature"]),
            }).then(
                (resp) => {
                    if (this.isNew) {
                        this.contact = {};
                        this.canEditEmail = true;
                        this.canEditContact = true;
                        resp.contact = {
                            contacts: {
                                contact: {
                                    address: [
                                        {
                                            address_type: 3,
                                        },
                                        {
                                            address_type: 4,
                                        },
                                    ],
                                },
                            },
                        };

                        this.contactModel =
                            (resp.contact && resp.contact.contacts && resp.contact.contacts.contact) || {};

                        if (this.contactModel) {
                            this.contactModel.address = this.contactModel.address || [];
                        }

                        if (this.data.contactTransferData) {
                            const { first_name, middle_name, last_name, work_address } = this.data.contactTransferData;
                            this.contact = { first_name, middle_name, last_name };
                            this.workAddr = work_address;
                        }

                        this.data.hasEmailOptions = false;
                        this.data.EmailSignature =
                            resp.prefs && resp.prefs.EmailSignature && resp.prefs.EmailSignature.value;
                        this.data.EmailCopyMe =
                            resp.prefs && resp.prefs.EmailCopyMe && resp.prefs.EmailCopyMe.value === "T";
                    } else {
                        if (!this.contact && resp.contact && resp.contact.contacts && resp.contact.contacts.contact) {
                            this.contact = resp.contact.contacts.contact;
                        }

                        this.canEditEmail = resp.fls.CHG_PSWD === "F" && ["F", "C"].indexOf(resp.fls.CU_CONTACT) > -1; //ANG-1806
                        this.canEditContact = ["F", "C"].indexOf(resp.fls.CU_CONTACT) > -1;

                        this.contactModel = this.contact || {};

                        if (this.contactModel) {
                            this.contactModel.address = this.contactModel.address || [];
                        }

                        const workAddrFormat = S25Util.propertyGetParentWithChildValue(
                            this.contact,
                            "address_type",
                            "3",
                        );
                        this.workAddr = S25Util.extractAddress(workAddrFormat, 3);
                        if (!this.workAddr) {
                            this.workAddr = { address_type: 3 };
                            if (workAddrFormat && workAddrFormat.email) {
                                this.workAddr.email = workAddrFormat.email;
                            }
                        } else {
                            this.workAddr.street = workAddrFormat?.street_address;
                            this.workAddr.email = workAddrFormat?.email;
                        }

                        const homeAddrFormat = S25Util.propertyGetParentWithChildValue(
                            this.contact,
                            "address_type",
                            "4",
                        );
                        this.homeAddr = S25Util.extractAddress(homeAddrFormat, 4);
                        if (!this.homeAddr) {
                            this.homeAddr = { address_type: 4 };
                            if (homeAddrFormat && homeAddrFormat.email) {
                                this.homeAddr.email = homeAddrFormat.email;
                            }
                        } else {
                            this.homeAddr.street = homeAddrFormat?.street_address;
                            this.homeAddr.email = homeAddrFormat?.email;
                        }

                        this.data.hasEmailOptions = true;
                        this.data.EmailSignature =
                            resp.prefs && resp.prefs.EmailSignature && resp.prefs.EmailSignature.value;
                        this.data.EmailCopyMe =
                            resp.prefs && resp.prefs.EmailCopyMe && resp.prefs.EmailCopyMe.value === "T";
                    }

                    this.initialState = this.getState();
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                    this.init = true;
                    this.cd.detectChanges();
                },
                (error) => {
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                },
            );
        });
    }

    getState = function () {
        return (
            JSON.stringify(S25Util.prettifyJson(this.contactModel)) + this.data.EmailSignature + this.data.EmailCopyMe
        );
    };

    setEmailSignature = function (data: string) {
        return PreferenceService.setPreference("EmailSignature", data.replace(/\n/g, ""))
            .then(() => {
                this.data.EmailSignature = data;
                return this.data.EmailSignature;
            })
            .then(() => {
                return PreferenceService.getPreferences(["EmailSignature"]).then((resp) => {
                    const response = resp.EmailSignature;
                    return this.data.EmailSignature === response.value;
                });
            });
    };

    findDuplicateContacts(copyAction?: "edit" | "create", props?: any) {
        this.storedCopyAction = copyAction;
        this.storedObjectProps = props;

        if (this.dupContactMessage || this.data.userSettings) {
            this.cancelConfirm();
            this.onSave ? this.onSave(copyAction, props) : this.save();
        } else if (this.validate()) {
            // when confirming duplicate contact, disable form to avoid changes that would require another check
            const inputs = this.elementRef.nativeElement.querySelectorAll("input");
            if (inputs && inputs.length && inputs.length > 0) {
                inputs.forEach((input: HTMLInputElement) => {
                    input.disabled = true;
                });
            }

            return ContactService.getEmailCount(this.workAddr.email).then((count) => {
                S25LoadingApi.init(this.elementRef.nativeElement);

                if (count > 0) {
                    if (this.data.copyContact) {
                        const copyButtons =
                            this.elementRef.nativeElement.parentElement.parentElement.parentElement.querySelectorAll(
                                ".aw-button--primary",
                            );

                        if (copyButtons && copyButtons.length && copyButtons.length > 0) {
                            copyButtons.forEach((button: HTMLButtonElement) => (button.disabled = true));
                        }
                    }

                    return ContactService.getContactByEmail(this.workAddr.email, this.contact.last_name).then(
                        (data) => {
                            const matches = S25Util.array.forceArray(data?.contacts?.contact);
                            const exactMatches = matches?.filter((contact: any) => {
                                return (
                                    contact?.last_name.toLowerCase() === this.contact.last_name.toLowerCase() &&
                                    (S25Util.array.forceArray(contact?.address)[0] as any)?.email.toLowerCase() ===
                                        this.workAddr.email.toLowerCase()
                                );
                            });

                            if (exactMatches && exactMatches.length && exactMatches.length > 0) {
                                this.dupContactMessage =
                                    "The provided work email is currently registered to the following contact record(s). Please confirm new contact.";
                                this.exactContactMatches = exactMatches?.map((contact: any) => {
                                    return { itemId: contact.cont_id, itemName: contact.contact_name };
                                });
                            } else {
                                this.dupContactMessage = `The provided work email is currently registered to ${count} contact record(s). Please confirm new contact.`;
                            }

                            S25LoadingApi.destroy(this.elementRef.nativeElement);
                            this.cd.detectChanges();
                        },
                    );
                } else {
                    this.onSave ? this.onSave(copyAction, props) : this.save();
                }
            });
        }
    }

    cancelConfirm() {
        this.dupContactMessage &&= undefined;
        this.exactContactMatches &&= [];

        if (this.data.copyContact) {
            const copyButtons =
                this.elementRef.nativeElement.parentElement.parentElement.parentElement.querySelectorAll(
                    ".aw-button--primary",
                );

            if (copyButtons && copyButtons.length && copyButtons.length > 0) {
                copyButtons.forEach((button: HTMLButtonElement) => (button.disabled = false));
            }
        }

        const inputs = this.elementRef.nativeElement.querySelectorAll("input");
        if (inputs && inputs.length && inputs.length > 0) {
            inputs.forEach((input: HTMLInputElement) => {
                input.disabled = false;
            });
        }

        this.cd.detectChanges();
    }

    save = function () {
        if (this.validate()) {
            this.contactModel.address = [
                S25Util.addressObjToNode(this.workAddr, 3),
                S25Util.addressObjToNode(this.homeAddr, 4),
            ];
        } else {
            return;
        }

        if (this.initialState !== this.getState()) {
            //if valid and state has changed
            S25LoadingApi.init(this.elementRef.nativeElement);

            if (this.isNew) {
                this.contactModel.address.map((address: AddressI) => {
                    address.status = "new";
                });
            } else {
                this.contactModel.address.map((address: AddressI) => {
                    address.status = "mod";
                });
            }

            this.disableSaveButton = true;
            this.errorShow = false;

            return S25Util.all({
                contactSave: this.canEditContact && ContactService.setContact(this.contactModel, this.isNew),
                EmailCopyMeSave:
                    this.fields.hasEmailOptions &&
                    S25Util.isDefined(this.data.EmailCopyMe) &&
                    PreferenceService.setPreference("EmailCopyMe", (this.data.EmailCopyMe && "T") || "F"),
                EmailSignatureSave:
                    this.fields.hasEmailOptions &&
                    S25Util.isDefined(this.data.EmailSignature) &&
                    this.setEmailSignature(this.data.EmailSignature),
            }).then(
                (resp) => {
                    S25LoadingApi.destroy(this.elementRef.nativeElement);

                    if (
                        !this.canEditContact ||
                        S25Util.valueFind(resp, "msg_id", "CO_I_SAVE") ||
                        S25Util.valueFind(resp, "msg_id", "CO_I_CREATED") ||
                        (this.fields.hasEmailOptions && resp.EmailSignatureSave)
                    ) {
                        if (this.data && this.data.postSave && !this.data.eventForm) {
                            this.data.postSave();
                        }
                        this.disableSaveButton = false;

                        if (this.data.eventForm) {
                            this.showSuccessMsg = true;
                            this.itemId = resp?.contactSave.results.info.id;
                        }
                        if (this.data.userSettings) {
                            alert(
                                '<div style="margin-bottom: 5em;">\n            <div style="width: fit-content; margin: 0 auto; padding-top: 3em; font-size: 1.25em">\n                <svg style="margin-right: 1em; background-color: mediumseagreen; height: 3em; width: 3em; border-radius: 50%; color: white; padding: 7px" role="img">\n                    <title>Success</title>\n                    <use\n                        xmlns:xlink="http://www.w3.org/1999/xlink"\n                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"\n                    ></use>\n                </svg>\n                Contact Successfully Updated\n            </div>\n        </div>',
                            );
                        }
                        this.cd.detectChanges();
                    } else {
                        alert(this.lang.contact_info_update_failure);
                        this.disableSaveButton = false;
                    }
                },
                function (error) {
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                    this.errorShow = true;
                    this.disableSaveButton = false;
                    console.log(error);
                    S25Util.showError(error);
                },
            );
        }
    };

    requiredFieldTemplate =
        "{{FIRST_UPPER_FIELD_DISPLAY}} is a required field. Please enter a {{FIELD_DISPLAY}} for this contact record.";
    requiredFields = {
        lastName: { display: "last name" },
        workEmail: { display: "work email" },
    };

    displayRequiredFieldMsg = function (field: any) {
        if (field && this.requiredFields[field]) {
            return this.requiredFieldTemplate
                .replace(
                    "{{FIRST_UPPER_FIELD_DISPLAY}}",
                    this.requiredFields[field].display[0].toUpperCase() +
                        this.requiredFields[field].display.substring(1),
                )
                .replace("{{FIELD_DISPLAY}}", this.requiredFields[field].display);
        }
        return "";
    };

    validate = function () {
        if (this.canEditContact && !this.contact.last_name) {
            alert(this.displayRequiredFieldMsg("lastName"));
            return false;
        } else if (this.canEditEmail && (!this.workAddr || !this.workAddr.email)) {
            //if user can even edit workAddr.email, and it is empty
            alert(this.displayRequiredFieldMsg("workEmail"));
            return false;
        } else if (
            this.canEditEmail &&
            this.workAddr &&
            this.workAddr.email &&
            !S25Util.isValidEmail(this.workAddr.email)
        ) {
            //here work email must have data bc the check above ensures it
            alert("Please enter a valid email address for the contact work email.");
            return false;
        } else if (
            this.canEditEmail &&
            this.homeAddr &&
            this.homeAddr.email &&
            !S25Util.isValidEmail(this.homeAddr.email)
        ) {
            //if home email has data and it is not a valid email
            alert("Please enter a valid email address for the contact home email.");
            return false;
        } else {
            return true;
        }
    };

    updateContactModel(prop?: string, val?: string) {
        if (val) this.contactModel[prop] = val;

        this.contactModel.address = [
            S25Util.addressObjToNode(this.workAddr, 3),
            S25Util.addressObjToNode(this.homeAddr, 4),
        ];

        this.contactChange.emit(this.contactModel);
    }

    updateEmailSignature(val: string) {
        this.data.EmailSignature = val;
    }

    updateEmailCopyMe(checked: boolean) {
        this.data.EmailCopyMe = checked;
    }

    toggleForm(form: string) {
        if (this.dupContactMessage) return;

        if (this.data.eventForm) {
            if (form === "work") {
                this.displayWorkAddressFrom = !this.displayWorkAddressFrom;
            } else if (form === "home") {
                this.displayHomeAddressForm = !this.displayHomeAddressForm;
            }
        }
    }

    goToRecord() {
        return StateService.gotoItem(
            {
                itemTypeId: 3,
                itemId: this.itemId,
                forceView: "details",
            },
            true,
        );
    }
}
