import { S25Const } from "../../util/s25-const";
import { StateService } from "../../services/state.service";
import { FlsService } from "../../services/fls.service";
import { StandoutClassroomService } from "../../services/standout.classroom.service";
import { EventService } from "../../services/event.service";
import { S25Util } from "../../util/s25-util";
import { NotificationService } from "../../services/notification.service";
import { LangService } from "../../services/lang.service";
import { ComponentRef } from "@angular/core";
import { S25ContextMenuComponent } from "../s25-context-menu/context.menu.component";

export class ContextMenuService {
    public static contextMenuInstance: ComponentRef<S25ContextMenuComponent>;

    private static type2SubPerms: any = {
        1: async (itemId: number) => await EventService.formEventCopyEditEmailPermsFromId(itemId),
    };
    public static async hydrateMenu(itemTypeId: number, itemId: number, bean: any, itemExt?: any) {
        bean ??= {};
        itemExt = { ...itemExt };
        itemTypeId = S25Const.itemName2Id[itemTypeId] ?? itemTypeId;
        itemTypeId = StateService.ItemTypeNorm[itemTypeId] ?? itemTypeId;

        const [fls, hasSoc, lang] = await Promise.all([
            FlsService.getFls(),
            itemTypeId === 1 && StandoutClassroomService.canHaveSOCByEvent(itemId),
            LangService.getLang(),
        ]);

        const hasPerms =
            fls &&
            !(itemTypeId === 4 && fls.SPACE_LIST === "N") &&
            !(itemTypeId === 6 && fls.RESOURCE_LIST === "N") &&
            !(itemTypeId === 2 && fls.CU_ACCOUNT === "N");

        if (hasPerms) {
            const item = { ...itemExt, itemId, itemTypeId };

            const subPermsData = (await ContextMenuService.type2SubPerms[itemTypeId]?.(itemId)) ?? {};

            subPermsData.hasOpenInNewTab = !S25Util.mobileCheck();
            subPermsData.hasSocOption = hasSoc;

            //note we preserve the bean object reference, which is important as the caller expects to use attached functions after passing in a bean
            Object.assign(bean, {
                //set popup bean
                hideEventParams: { event: "unfocus" },
                data: {
                    item: item,
                    lang: lang,
                    subPerms: subPermsData,
                    itemUrl: `#!/home/${S25Const.itemId2Name[itemTypeId]}/${itemId}/details`, //used for open in new tab link
                    openInNewTab: () => bean.hidePopup(),
                    setStandOut: async () => {
                        try {
                            await StandoutClassroomService.eventSetSOC(item.itemId, true);
                            const name = await EventService.getEventName(item.itemId);
                            NotificationService.post(`StandOut Classroom added to ${name}`);
                        } catch (error) {
                            S25Util.showError(error);
                        }
                    },
                    details: () => {
                        //quick jump to object details
                        StateService.gotoItem({
                            ...item,
                            forceView: "details",
                            itemTypeId: item.itemTypeId === 19991 ? 1 : item.itemTypeId,
                        });
                    },
                    calendar: () => {
                        //quick jump to object calendar
                        StateService.gotoItem({
                            ...item,
                            forceView: "calendar",
                            itemTypeId: item.itemTypeId === 19991 ? 1 : item.itemTypeId,
                        });
                    },
                    list: () => {
                        //quick jump to object list
                        StateService.gotoItem({
                            ...item,
                            forceView: "list",
                            itemTypeId: item.itemTypeId === 19991 ? 1 : item.itemTypeId,
                        });
                    },
                    availability: () => {
                        //quick jump to object availability
                        StateService.gotoItem({
                            ...item,
                            forceView: "availability",
                            itemTypeId: item.itemTypeId === 19991 ? 1 : item.itemTypeId,
                        });
                    },
                    emailEvent: () => {
                        StateService.gotoItem({
                            ...item,
                            forceView: "details",
                            itemTypeId: item.itemTypeId === 19991 ? 1 : item.itemTypeId,
                        }).then(() => {
                            setTimeout(() => {
                                window.angBridge.$injector.get("MoreActionsApi").open("email");
                            });
                        });
                    },
                    editEvent: () => {
                        this.contextMenuInstance?.destroy();
                        this.contextMenuInstance = null;

                        if (subPermsData.hasRelatedEvents) {
                            window.angBridge.$injector
                                .get("s25ModalService")
                                .modal("copy-edit-event", { objId: item.itemId, type: "edit" });
                        } else {
                            StateService.gotoRose({
                                ...item,
                                itemTypeId: item.itemTypeId === 19991 ? 1 : item.itemTypeId,
                            });
                        }
                    },
                    copyEvent: () => {
                        this.contextMenuInstance?.destroy();
                        this.contextMenuInstance = null;
                        //note: always open copy modal when copying event
                        window.angBridge.$injector.get("s25ModalService").modal("copy-edit-event", {
                            objId: item.itemId,
                            type: "clone",
                        });
                    },
                },
            });

            return bean;
        }
    }
}
