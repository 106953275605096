import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { S25CheckboxComponent } from "./s25.checkbox.component";
import { S25ShiftSelectableModule } from "../s25-shift-select/s25.shift.selectable.module";
import { S25ShiftSelectableCheckboxComponent } from "./shift.selectable.checkbox.component";

@NgModule({
    declarations: [S25CheckboxComponent, S25ShiftSelectableCheckboxComponent],
    imports: [CommonModule, FormsModule, S25ShiftSelectableModule],
    providers: [S25CheckboxComponent, S25ShiftSelectableCheckboxComponent],
    exports: [S25CheckboxComponent, S25ShiftSelectableCheckboxComponent],
})
export class S25CheckboxModule {
    constructor() {}
}
