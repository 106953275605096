import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { CustomAttributeService } from "../../services/custom.attribute.service";
import { Item } from "../../pojo/Item";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Rules } from "../s25-rule-tree/s25.rule.const";

@TypeManagerDecorator("s25-ng-custom-attribute-value")
@Component({
    selector: "s25-ng-custom-attribute-value",
    template: `
        <ng-container *ngIf="isInit">
            <div class="attributeRow">
                <label [for]="'customAttributeOperator' + currentCount">Operator: </label>
                <select
                    [ngModel]="operator"
                    (ngModelChange)="onOperatorChange($event)"
                    class="cn-form__control"
                    [id]="'customAttributeOperator' + currentCount"
                    name="operator"
                >
                    <option *ngFor="let operator of availableOperators" [ngValue]="operator">
                        {{ operator }}
                    </option>
                </select>
            </div>

            <div class="attributeRow" [ngSwitch]="attributeType">
                <label [for]="'customAttributeValue' + currentCount">Value: </label>

                <s25-toggle-button
                    *ngSwitchCase="AttributeType.Boolean"
                    [modelValue]="value"
                    (modelValueChange)="onValueChange($event)"
                    [id]="'customAttributeValue' + currentCount"
                    name="attribute value"
                ></s25-toggle-button>

                <ng-container *ngSwitchCase="AttributeType.Text">
                    <input
                        *ngIf="!discreteOptions[attributeId] || operator === 'contains'"
                        class="c-input"
                        type="text"
                        [maxLength]="80"
                        [ngModel]="value"
                        (ngModelChange)="onValueChange($event)"
                        [id]="'customAttributeValue' + currentCount"
                        name="attribute value"
                    />

                    <select
                        *ngIf="!!discreteOptions[attributeId] && operator !== 'contains'"
                        [ngModel]="value"
                        (ngModelChange)="onValueChange($event)"
                        class="c-input"
                        [id]="'customAttributeValue' + currentCount"
                        name="attribute value"
                    >
                        <option value="" disabled selected>Select a value</option>
                        <option *ngFor="let opt of discreteOptions[attributeId]" [ngValue]="opt">{{ opt }}</option>
                    </select>
                </ng-container>

                <input
                    *ngSwitchCase="AttributeType.Integer"
                    class="c-input"
                    type="number"
                    step="1"
                    [ngModel]="value"
                    (ngModelChange)="onValueChange($event)"
                    [id]="'customAttributeValue' + currentCount"
                    name="attribute value"
                />

                <input
                    *ngSwitchCase="AttributeType.Float"
                    class="c-input"
                    type="number"
                    [ngModel]="value"
                    (ngModelChange)="onValueChange($event)"
                    [id]="'customAttributeValue' + currentCount"
                    name="attribute value"
                />

                <textarea
                    *ngSwitchCase="AttributeType.LongText"
                    class="c-input"
                    [maxLength]="10000"
                    [ngModel]="value"
                    (ngModelChange)="onValueChange($event)"
                    [id]="'customAttributeValue' + currentCount"
                    name="attribute value"
                ></textarea>

                <input
                    *ngSwitchCase="AttributeType.URL"
                    class="c-input"
                    type="text"
                    [maxLength]="250"
                    [ngModel]="value"
                    (ngModelChange)="onValueChange($event)"
                    [id]="'customAttributeValue' + currentCount"
                    name="attribute value"
                />

                <s25-ng-editable-date
                    *ngSwitchCase="AttributeType.Date"
                    [val]="value"
                    (valChange)="onValueChange($event.toISOString())"
                    [id]="'customAttributeValue' + currentCount"
                    name="attribute value"
                ></s25-ng-editable-date>

                <s25-ng-editable-date-time
                    *ngSwitchCase="AttributeType.DateTime"
                    [val]="value"
                    (valChange)="onValueChange($event)"
                    [outputString]="true"
                    [id]="'customAttributeValue' + currentCount"
                    name="attribute value"
                ></s25-ng-editable-date-time>

                <s25-timepicker
                    *ngSwitchCase="AttributeType.Time"
                    [modelValue]="value"
                    (modelValueChange)="onValueChange($event.toISOString())"
                    [defaultNow]="true"
                    [id]="'customAttributeValue' + currentCount"
                    name="attribute value"
                ></s25-timepicker>

                <ng-container *ngSwitchCase="AttributeType.Location">
                    <ng-container *ngTemplateOutlet="multiselect"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="AttributeType.Resource">
                    <ng-container *ngTemplateOutlet="multiselect"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="AttributeType.Organization">
                    <ng-container *ngTemplateOutlet="multiselect"></ng-container>
                </ng-container>
            </div>

            <ng-template #multiselect>
                <s25-ng-dropdown-search-criteria
                    [type]="searchCriteria[$any(attributeType)]"
                    [chosen]="value"
                    (chosenChange)="onValueChange($any($event))"
                    [id]="'customAttributeValue' + currentCount"
                    name="attribute value"
                ></s25-ng-dropdown-search-criteria>
            </ng-template>
        </ng-container>
    `,
    styles: `
        .attributeRow {
            display: flex;
            gap: 1em;
            align-items: center;
            margin: 0.5em 0;
        }

        .attributeRow > label:first-child {
            min-width: 10em;
            margin: 0;
        }

        .attributeRow > input {
            min-width: 15em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25CustomAttributeValueComponent implements OnInit {
    @Input() attributeId: number;
    @Input() attributeType: Rules.AttributeType;
    @Input() operator?: Rules.Operator;
    @Input() value?: Rules.ConditionValue;

    @Output() operatorChange = new EventEmitter<Rules.Operator>();
    @Output() valueChange = new EventEmitter<Rules.ConditionValue>();

    AttributeType = Rules.attributeType;

    isInit = false;
    discreteOptions: Record<number, string[]> = {};
    availableOperators: Rules.Operator[] = [];
    searchCriteria = {
        [Item.Ids.Location]: "locations",
        [Item.Ids.Resource]: "resources",
        [Item.Ids.Organization]: "organizations",
    };
    public static count = 0;
    currentCount: number;

    constructor(private changeDetector: ChangeDetectorRef) {
        this.currentCount = S25CustomAttributeValueComponent.count;
        S25CustomAttributeValueComponent.count++;
    }

    async ngOnInit() {
        const { attributeId, attributeType, operator, value } = this;
        await this.setDiscreteOptions();
        this.setAvailableOperators();
        if (!this.operator) this.setDefaultOperator();
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    async setDiscreteOptions() {
        const discreteOptions = await CustomAttributeService.getAllDiscreteOptions();
        for (let option of discreteOptions) this.discreteOptions[option.itemId] = option.opt;
    }

    setAvailableOperators() {
        switch (this.attributeType) {
            case Rules.attributeType.DateTime:
            case Rules.attributeType.Date:
            case Rules.attributeType.Time:
            case Rules.attributeType.Float:
            case Rules.attributeType.Integer:
                this.availableOperators = ["=", "<", "<=", ">=", ">"];
                break;
            case Rules.attributeType.Boolean:
            case Rules.attributeType.Organization:
            case Rules.attributeType.Location:
            case Rules.attributeType.Resource:
                this.availableOperators = ["="];
                break;
            case Rules.attributeType.Text:
            case Rules.attributeType.LongText:
            case Rules.attributeType.URL:
                this.availableOperators = ["=", "contains"];
                break;
        }
    }

    setDefaultOperator() {
        this.onOperatorChange(this.availableOperators[0]);
    }

    onOperatorChange(operator: Rules.Operator) {
        this.operator = operator;
        this.operatorChange.emit(operator);
        this.changeDetector.detectChanges();
    }

    onValueChange(value: Rules.ConditionValue) {
        this.value = value;
        this.valueChange.emit(value);
        this.changeDetector.detectChanges();
    }
}
