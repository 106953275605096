import { ChangeDetectionStrategy, Component, Input, OnInit, signal } from "@angular/core";
import { CustomAttributeService } from "../../services/custom.attribute.service";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25Util } from "../../util/s25-util";

@TypeManagerDecorator("s25-ng-custom-attribute-options")
@Component({
    selector: "s25-ng-custom-attribute-options",
    template: `
        @if (init()) {
            <div class="discreet-options-wrapper">
                @if (!inTable || mode() === "edit") {
                    <ol>
                        @for (option of options(); track $index) {
                            <li class="c-margin-bottom--half">
                                <div class="option-container">
                                    <label>
                                        <input [(ngModel)]="option.value" class="c-input" />
                                    </label>
                                    <button class="c-textButton" (click)="moveUp($index)">
                                        <s25-ng-icon [type]="'arrowUp'"></s25-ng-icon>
                                    </button>
                                    <button class="c-textButton" (click)="moveDown($index)">
                                        <s25-ng-icon [type]="'arrowDown'"></s25-ng-icon>
                                    </button>
                                    <button class="c-textButton" (click)="removeOption($index)">
                                        <s25-ng-icon [type]="'close'"></s25-ng-icon>
                                    </button>
                                </div>
                            </li>
                        }
                    </ol>
                }

                <button class="aw-button aw-button--outline ngFloatRight" (click)="addOption()">Add Option</button>
            </div>
        }
    `,
    styles: `
        .discreet-options-wrapper {
            width: fit-content;
            margin: 0 auto 1em;
        }

        :host ::ng-deep s25-ng-icon:hover {
            cursor: pointer;
        }

        :host ::ng-deep .option-container {
            display: flex;
            gap: 5px;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25CustomAttributeOptionsComponent implements OnInit {
    @Input() attrId: number;
    @Input() inTable: boolean;

    init = signal(false);
    mode = signal<"link" | "edit">("link");
    options = signal<{ value: string }[]>(null);

    async ngOnInit() {
        if (this.inTable) {
            const options = await CustomAttributeService.getDiscreteOptions(this.attrId);
            this.options.set(options?.map((option) => ({ value: option })) ?? []);
            this.mode.set("edit");
        }

        this.init.set(true);
    }

    save() {
        const discreetOptions = S25Util.array.uniqueByProp(
            this.options()
                .map((option, i) => ({ value: option.value, sort_order: i }))
                .filter((option) => !!option.value?.length),
            "value",
        );

        return CustomAttributeService.putDiscreteOptions(this.attrId, discreetOptions);
    }

    addOption() {
        this.options.update((options) => [...options, { value: "" }]);
    }

    removeOption(index: number) {
        this.options.update((options) => options.filter((_, i) => i !== index));
    }

    moveUp(index: number) {
        if (index > 0) {
            this.options.update((options) => {
                [options[index - 1], options[index]] = [options[index], options[index - 1]];
                return options;
            });
        }
    }

    moveDown(index: number) {
        if (index < this.options().length - 1) {
            this.options.update((options) => {
                [options[index], options[index + 1]] = [options[index + 1], options[index]];
                return options;
            });
        }
    }
}
