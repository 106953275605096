import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { ShiftSelectable } from "../s25-shift-select/s25.shift.selectable.abstract";

@TypeManagerDecorator("s25-ng-checkbox")
@Component({
    selector: "s25-ng-checkbox",
    template: `
        @if (init) {
            <div
                class="wrapper"
                [class]="containerClass"
                [ngClass]="{
                    rowCheckboxLabelContainer: labelClass == 'rowCheckBoxLabel',
                    'c-displayInlineBlock': labelClass == 'inline',
                }"
                (click)="passive && $event.preventDefault()"
                (keydown.enter)="passive && $event.preventDefault()"
                (keydown.space)="passive && $event.preventDefault()"
            >
                @if (init) {
                    <input
                        type="checkbox"
                        [id]="idString"
                        [(ngModel)]="modelValue"
                        [ngClass]="{ checked: modelValue, 'c-label--input': true }"
                        (ngModelChange)="onChangeHandler()"
                        [disabled]="disabled"
                        [attr.aria-label]="ariaLabel"
                    />
                    <label [for]="idString" class="c-label--checkbox {{ labelClass }}">
                        @if (!noLabel) {
                            <span [style.margin-left]="'0.5em'">
                                <ng-content></ng-content>
                            </span>
                        }
                    </label>
                }
            </div>
        }
    `,
    styles: `
        s25-ng-checkbox .wrapper {
            vertical-align: middle;
            margin: 0.25em 0;
        }

        s25-ng-checkbox .c-label--checkbox {
            display: flex !important;
            margin: 0;
        }

        s25-ng-checkbox .c-label--checkbox::before {
            margin-right: 0;
        }

        s25-ng-checkbox input[disabled] + label {
            opacity: 0.75;
        }

        s25-ng-checkbox input[disabled] + label,
        s25-ng-checkbox input[disabled] + label:hover {
            cursor: not-allowed;
        }

        s25-ng-checkbox input[disabled] + label::before,
        s25-ng-checkbox input[disabled] + label:hover::before {
            border-style: dashed;
        }

        .nm-party--on s25-ng-checkbox input[disabled] + label:hover::before {
            border-color: #3f627f !important;
        }
    `,
    providers: [
        {
            provide: ShiftSelectable,
            useExisting: forwardRef(() => S25CheckboxComponent),
        },
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25CheckboxComponent implements OnInit, ShiftSelectable {
    @Input() modelValue: boolean = false;
    @Input() onChange: Function; //(obj: any) => any = undefined;
    @Input() blockChange: () => boolean | Promise<boolean>;
    //DOM tools
    @Input() disabled: boolean = false;
    @Input() containerClass: string = "";
    @Input() labelId: string = null;
    @Input() labelClass: string = "inline"; //"rowCheckBoxLabel", "inline"
    @Input() ariaLabel: string = null;
    @Input() noLabel = false; // Hides label when true
    @Input() passive: boolean; // Ignore internal changes

    @Output() modelValueChange = new EventEmitter<boolean>();

    id: string;
    idString: string;
    init: boolean = false;
    static count: number = 0;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
        S25CheckboxComponent.count++;
        this.id = "s25ngCheckbox-" + S25CheckboxComponent.count;
    }

    ngOnInit(): void {
        this.disabled = S25Util.coalesce(this.disabled, false);
        this.idString = this.id + "-" + this.labelId;
        this.init = true;
        this.cd.detectChanges();
    }

    async onChangeHandler() {
        !!(await this.blockChange?.()) && this.resetAfterBlock();
        this.onChange && this.onChange({ data: this.modelValue });
        this.modelValueChange.emit(this.modelValue);
        this.cd.detectChanges();
    }

    select() {
        if (this.modelValue === true) return;
        this.modelValue = true;
        return this.onChangeHandler();
    }

    deselect() {
        if (this.modelValue === false) return;
        this.modelValue = false;
        return this.onChangeHandler();
    }

    isSelected(): boolean {
        return this.modelValue;
    }

    resetAfterBlock() {
        this.modelValue = !this.modelValue;
        this.init = false;
        this.cd.detectChanges();
        this.ngOnInit();
        // if using keyboard, alerts remove focus from form flow - refocus when closing modal alert
        const input = this.elementRef.nativeElement.querySelector("input");
        input?.focus();
    }
}
