//@author: devin
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { S25ContextMenuComponent } from "./context.menu.component";

@NgModule({
    declarations: [S25ContextMenuComponent],
    imports: [CommonModule, NgbModule],
    providers: [S25ContextMenuComponent],
    exports: [S25ContextMenuComponent],
})
export class ContextMenuModule {}
