import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UserprefService } from "../../services/userpref.service";
import { Item } from "../../pojo/Item";
import { TypeManagerDecorator } from "../../main/type.map.service";

export type Conflict = SpaceConflict | ResourceConflict;
export interface SpaceConflict extends ConflictDefaults {}
export interface ResourceConflict extends ConflictDefaults {}
export interface ConflictDefaults {
    candidateDate: Date;
    candidateId: number;
    conflictEnd: Date;
    conflictHash: string;
    conflictName: string;
    conflictId: number;
    conflictOverride: ConflictOverride;
    conflictRelation: "direct" | "also";
    conflictShare: boolean;
    conflictStart: Date;
    conflictType: "rsrv" | "block" | "hours" | "blackout";
    date: string;
    isReal: boolean;
    itemId: number;
    itemName: string;
    itemTypeId: Item.Id;
    occUUID: string;
    uuid: string;
}

export interface ConflictOverride {
    blackout: boolean;
    block: boolean;
    closed: boolean;
    share: boolean;
}

@TypeManagerDecorator("s25-ng-conflicts")
@Component({
    selector: "s25-ng-conflicts",
    template: `
        <div>
            <div *ngIf="isInit" class="cn-alert cn-alert--info cn-alert--{{ isReal ? 'danger' : 'notRealConflict' }}">
                <div
                    class="cn-alert__icon cn-icon {{ isReal ? 'cn-alert__icon--red' : 'cn-alert__icon--blue' }}"
                    name="alert--info"
                >
                    <svg class="cn-svg-icon" role="img">
                        <title>Conflicts Warning</title>
                        <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#warnings--yield"
                        ></use>
                    </svg>
                </div>
                <div class="cn-alert__label">
                    <div class="c-red ngBold c-margin-bottom--half">Conflicts on:</div>
                    <p class="ngObjOccConflict" *ngFor="let conflict of conflicts | slice: 0 : 5; let last = last">
                        {{ conflict.candidateDate | dateFormat: dateFormat }}{{ last ? "" : ", " }}
                    </p>
                    <div *ngFor="let date of candidateDates" class="rose-form--conflictsRow">
                        <p class="rose-form--conflictsDate c-padding-bottom--quarter">
                            {{ date.candidateDate | dateFormat: dateFormat }}
                        </p>
                        <div>
                            <ul>
                                <li *ngFor="let conflict of date.conflicts">
                                    <span>{{ conflict.itemName }}</span>
                                    <div [ngSwitch]="conflict.conflictType">
                                        <span *ngSwitchCase="'blackout'">Blackout: {{ conflict.conflictName }}</span
                                        ><span *ngIf="conflict.conflictOverride?.blackout"> (Overridden)</span>
                                        <span *ngSwitchCase="'hours'">Closed: {{ conflict.conflictName }}</span
                                        ><span *ngIf="conflict.conflictOverride?.closed"> (Overridden)</span>
                                        <span *ngSwitchCase="'block'">
                                            Blocked:
                                            <s25-item-event [modelBean]="generateItem(conflict)"></s25-item-event>
                                            <span *ngIf="conflict.conflictOverride?.block"> (Overridden)</span>
                                        </span>
                                        <span *ngSwitchCase="'rsrv'">
                                            <s25-item-event [modelBean]="generateItem(conflict)"></s25-item-event>
                                            <span *ngIf="conflict.conflictShare && conflict.conflictOverride?.share">
                                                (Shared)</span
                                            >
                                        </span>
                                        <span *ngSwitchDefault>{{ conflict.conflictName }}</span>
                                    </div>
                                    <span
                                        >{{ conflict.conflictStart | dateFormat: timeFormat }} -
                                        {{ conflict.conflictEnd | dateFormat: timeFormat }}</span
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ConflictsComponent implements OnInit {
    @Input() model: Conflict[];
    itemHash: { [key: string]: { itemTypeId: Item.Id; itemId: number; itemName: string } } = {};
    candidateDates: { candidateDate: Date; conflicts: Conflict[] }[] = [];
    isReal: boolean;
    dateFormat: string;
    timeFormat: string;
    isInit: boolean;

    constructor(private changeDetector: ChangeDetectorRef) {}

    async ngOnInit() {
        const candidateDateHash: { [key: string]: any } = {};
        for (let conflict of this.model) {
            this.isReal = this.isReal || conflict.isReal;
            const key = Number(conflict.candidateDate);
            if (!candidateDateHash[key]) {
                const obj: any = { candidateDate: conflict.candidateDate, conflicts: [] };
                candidateDateHash[key] = obj;
                this.candidateDates.push(obj);
            }
            const obj = candidateDateHash[key];
            obj.conflicts.push(conflict);
        }

        const [dateFormat, timeFormat] = await Promise.all([
            UserprefService.getS25Dateformat(),
            UserprefService.getS25Timeformat(),
        ]);
        this.dateFormat = dateFormat;
        this.timeFormat = timeFormat;
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    generateItem(conflict: Conflict) {
        const key = conflict.conflictId + "&" + conflict.conflictName;
        if (!this.itemHash[key]) {
            this.itemHash[key] = { itemTypeId: 1, itemId: conflict.conflictId, itemName: conflict.conflictName };
        }
        return this.itemHash[key];
    }
}
