import { Component, Input } from "@angular/core";
import { S25CheckboxComponent } from "./s25.checkbox.component";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-shift-selectable-checkbox")
@Component({
    selector: "s25-ng-shift-selectable-checkbox",
    template: `
        <s25-ng-checkbox
            s25-ng-shift-selectable
            [shiftSelectIndex]="index"
            [shiftSelectGroup]="group"
            [modelValue]="modelValue"
            (modelValueChange)="modelValueChange.emit($event)"
            [onChange]="onChange"
            [disabled]="disabled"
            [containerClass]="containerClass"
            [labelId]="labelId"
            [labelClass]="labelClass"
            [ariaLabel]="ariaLabel"
            [noLabel]="noLabel"
        >
            <ng-content></ng-content>
        </s25-ng-checkbox>
    `,
})
export class S25ShiftSelectableCheckboxComponent extends S25CheckboxComponent {
    @Input() index: number;
    @Input() group: string;
}
